<template>
  <div id="LoginPage">
    <img
      src="https://cloud.b2co.cn/images/108.png"
      alt="logo"
      class="login_logo"
    />
    <div id="login-wrap">
      <!-- iframe 插槽 -->
      <iframe :src="QCodeSrc" class="login_iframe" />
    </div>
    <p class="help_tips">帮助与反馈</p>
  </div>
</template>

<script>
import appConfig from "@/appConfig.js";
export default {
  data() {
    return {
      QCodeSrc: "",
    };
  },
  mounted() {
    this.createScanQCode({
      self_redirect: false,
      id: "login-wrap",
      appid: appConfig.MINI_APP_ID,
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent(
        `${process.env.VUE_APP_ORIGIN}${appConfig.ENVPATH}`
      ),
      state: "",
      style: "black",
      href: "",
    });
  },
  methods: {
    // 官方示例
    createScanQCode(a) {
      var c = "default";
      a.self_redirect === !0
        ? (c = "true")
        : a.self_redirect === !1 && (c = "false");
      var e =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        a.appid +
        "&scope=" +
        a.scope +
        "&redirect_uri=" +
        a.redirect_uri +
        "&state=" +
        a.state +
        "&login_type=jssdk&self_redirect=" +
        c +
        "&styletype=" +
        (a.styletype || "") +
        "&sizetype=" +
        (a.sizetype || "") +
        "&bgcolor=" +
        (a.bgcolor || "") +
        "&rst=" +
        (a.rst || "");
      e += a.style ? "&style=" + a.style : "";
      e += a.href ? "&href=" + a.href : "";
      this.QCodeSrc = e;
    },
  },
};
</script>

<style scoped>
.login_logo {
  display: block;
  width: 100px;
  margin: 50px auto;
}

.help_tips {
  display: block;
  margin: 30px auto;
  cursor: pointer;
  color: #0082e6;
  text-align: center;
}

/* loading */
.el-loading-mask {
  display: none;
  position: absolute;
  z-index: 10000;
  background-color: hsla(0, 0%, 100%, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
}

.el-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}
.el-loading-spinner .circular {
  height: 42px;
  width: 42px;
  animation: loading-rotate 2s linear infinite;
}
.el-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #409eff;
  stroke-linecap: round;
}

@keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

@-webkit-keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
#login-wrap {
  text-align: center;
}
.login_iframe {
  width: 300px;
  height: 400px;
  border: none;
  overflow: hidden;
}
</style>
